import Helmet from 'react-helmet'
import Layout from '../../components/Section/Layout'
import ThankYou from '../../components/Section/ThankYou'
import React from 'react'

import { graphql } from 'gatsby'
import ThanksHero from '../../components/Block/ThanksHero'

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Thank You | Top KL Malaysia Photography Studio</title>
    </Helmet>
    <ThanksHero image={data.thankshero} />
  </Layout>
)

export const query = graphql`
  query {
    thankshero: file(relativePath: { eq: "contact-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 96) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
