import { H1, H3, Button } from '@bootstrap-styled/v4'
import React from 'react'
import styled from 'styled-components'
import Link from '../Common/Link'

const S = {}

S.Title = styled(H1)`
  margin-bottom: 0.5em;
`

S.SubTitle = styled(H3)`
  margin-bottom: 3em;
`

S.Button = styled(Button)`
  margin-bottom: 4em;
`

const ThankYouInfo = () => (
  <>
    <S.Title>Thank you</S.Title>
    <S.SubTitle>We will be in touch shortly</S.SubTitle>
    <Link to="/">
      <S.Button>Homepage</S.Button>
    </Link>
  </>
)

export default ThankYouInfo
