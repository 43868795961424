import { H1, Button } from '@bootstrap-styled/v4'

import Link from '../Common/Link'
import HeroImg from '../Common/HeroImg'
import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/constants'

const S = {}

S.Title = styled(H1)`
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.sans.thin};
  text-align: center;
  font-size: ${theme.fontSizes[5]}px;
  line-height: 1.2;
  letter-spacing: 2px;
  margin-bottom: 1rem;
  text-transform: uppercase;

  padding-left: 1rem;
  padding-right: 1rem;
  && {
  }

  ${theme.breakpoints.md} {
    font-size: ${theme.fontSizes[6]}px;
    margin-bottom: 1.5rem;
  }

  ${theme.breakpoints.lg} {
    width: 80vw;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[7]}px;
    margin-bottom: 2rem;

  }

  ${theme.breakpoints.xxl} {
    font-size: ${theme.fontSizes[8]}px;
    margin-bottom: 2.5rem;
    width: 60vw;
  }
`

S.Button = styled(Button)`
  && {
    color: ${theme.colors.white};
    border-color: ${theme.colors.white};
    margin-left: auto;
    margin-right: auto;
  }

  &&:hover {
    color: ${theme.colors.darkGray};
    background-color: ${theme.colors.white};
  }
`

S.Link = styled(Link)`
  &&{
    margin-left:auto;
    margin-right:auto;
  }
`

const ThanksHero = class extends React.Component {
  render() {
    const props = this.props;
    return (
      <HeroImg image={props.image}>
        <S.Title>Thank You</S.Title>
        <S.Link to="/">
            <S.Button  outline size="lg">Back to Homepage</S.Button>
        </S.Link>
      </HeroImg>

    )
  }
}

export default ThanksHero





