import { Col, Container } from '@bootstrap-styled/v4'

import ThankYouInfo from '../Block/ThankYouInfo'
import React from 'react'
import styled from 'styled-components'

const S = {}

S.Container = styled(Container)`
  margin-top: 4em;
  text-align: center;
`

const ThankYou = () => (
  <S.Container className="px-0">
    <ThankYouInfo />
  </S.Container>
)

export default ThankYou
